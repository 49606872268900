<template>
    <div class="question-page-content">
        <DedicatedQuestionInfo
            :is-custom-question="pageContainer.isCustom"
            :question-data="pageContainer.question"
        />

        <div class="dedicated-question__stats">
            <div class="question-score">
                <DashboardScore
                    :title="$t('Question Score')"
                    :description="$t('Based on all your Banks/filters applied')"
                    :fillData="pageContainer.scoreSectionData"
                />
            </div>
            <div class="question-chart section-card">
                <LineChart :chartData="pageContainer.dotChartSectionData" />
            </div>
        </div>

        <div class="dedicated-question__list">
            <div class="list-item">
                <DashboardCommonList
                    list-item-has-icon
                    hide-view-all
                    hide-footer
                    :filter-data="questionFiltersState"
                    :page-type="BusinessCommonListEnum.BANKS"
                    :score-data="pageContainer.bankSectionData"
                    :dataContainer="pageContainer"
                />
            </div>
            <div class="list-item">
                <DashboardCommonList
                    hide-view-all
                    hide-footer
                    :filter-data="questionFiltersState"
                    :page-type="BusinessCommonListEnum.COUNTRIES"
                    :score-data="pageContainer.countrySectionData"
                    :dataContainer="pageContainer"
                />
            </div>
        </div>

        <div class="dedicated-question__user-score section-card">
            <div class="all-comments-section">
                <DedicatedQuestionAllComments
                    v-if="pageContainer.commentsSectionData"
                    :dataContainer="pageContainer"
                    :commentsSectionData="pageContainer.commentsSectionData"
                    @onSortingChange="handleCommentSortingChange"
                />
            </div>
        </div>

        <CustomPagination
            v-if="pageContainer.commentsSectionData.pagination"
            :disabled="pageContainer.commentsSectionData.isLoading"
            :current-page="pageContainer.commentsSectionData.page"
            :total="pageContainer.commentsSectionData.pagination.totalItems"
            :pageSize="pageContainer.commentsSectionData.limit"
            @currentPageChange="handleCurrentPageChange"
            @pageSizeChange="handlePageSizeChange"
        />
    </div>
</template>

<script>
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";
import { SpecificQuestionDataContainer } from "@/store/models/business/dashboard/level4/SpecificQuestionDataContainer";
import DedicatedQuestionInfo from "@/buying-teams/pages/business/question/components/DedicatedQuestionInfo";
import DashboardScore from "@/buying-teams/pages/business/dashboard/components/overview/DashboardScore";
import LineChart from "@/buying-teams/shared-components/charts/LineChart";
import DashboardCommonList from "@/buying-teams/shared-components/dashboard/DashboardCommonList";
import DedicatedQuestionAllComments from "@/buying-teams/pages/business/question/components/DedicatedQuestionAllComments";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
import store from "@/store";
import {diagLog} from "@/core/helpers/GlobalHelper";

export default {
    name: "PageContentScoreImportance",
    components: {
        CustomPagination,
        DedicatedQuestionAllComments,
        DashboardCommonList,
        DashboardScore,
        DedicatedQuestionInfo,
        LineChart
    },
    props: {
        pageContainer: {
            type: [Object, SpecificQuestionDataContainer],
            required: true,
        },
        specificQuestionFilter: {
            type: Object,
            required: true,
        },
        questionFiltersState: Object,
    },
    data() {
        return {
            BusinessCommonListEnum
        }
    },
    created() {
        this.loadCommentsData();
    },
    methods: {
        handleCurrentPageChange(page) {
            this.pageContainer.commentsSectionData.page = page;
            this.loadCommentsData();
        },
        handlePageSizeChange(size) {
            this.pageContainer.commentsSectionData.page = 1;
            this.pageContainer.commentsSectionData.limit = size;
            this.loadCommentsData();
        },
        handleCommentSortingChange(sorting) {
            this.pageContainer.commentsSectionData.page = 1;
            this.pageContainer.commentsSectionData.orderKey = sorting;
            this.loadCommentsData();
        },
        async loadCommentsData() {
            if (this.pageContainer.commentsSectionData.isLoading) {
                return;
            }

            this.pageContainer.commentsSectionData.isLoading = true;
            try {
                let payload = {
                    page: this.pageContainer.commentsSectionData.page,
                    limit: this.pageContainer.commentsSectionData.limit,
                    order_key: this.pageContainer.commentsSectionData.orderKey,
                    order_direction: this.pageContainer.commentsSectionData.orderDirection,
                    filter: {
                        ...this.specificQuestionFilter,
                        questions: [this.$route.params.id]
                    },
                };
                if (this.pageContainer.isCustom) {
                    payload.is_custom = true;
                }

                let res = await store.dispatch('fetchSpecificQuestionComments', payload);
                diagLog( `fetched res for page ${payload.page}`, {res, payload});
                this.pageContainer.addCommentsSectionData(res.stats, res.paginationMetadata);
            } catch (e) {
                console.error({e});
            } finally {
                this.pageContainer.commentsSectionData.isLoading = false;
            }
        }
    }
}
</script>
