<template>
    <div class="dedicated-question" v-if="!loading">
        <a class="back-previous-screen" href="" @click.prevent="handleBackPrevPage">
            {{ "< " + $t("Back to [Previous Screen]", { previousScreen: 'Previous Screen' }) }}
        </a>

        <DedicatedQuestionFilter
            ref="specificQuestionFilter"
            :class="{'disabled-filter': !specificQuestionDataContainer}"
            :questionFiltersState="questionFiltersState"
            :feedbackSessions="feedbackSessions"
            @onApplyFilters="fetchQuestionPageData"
        />

        <template v-if="specificQuestionDataContainer">
            <!--Score & Importance -->
            <PageContentScoreImportance
                v-if="questionType === FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE"
                :page-container="specificQuestionDataContainer"
                :specific-question-filter="$refs.specificQuestionFilter.filterData"
                :question-filters-state="questionFiltersState"
            />

            <!-- Multiple Choice -->
            <PageContentMultipleChoice
                v-if="questionType === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE"
                :page-container="specificQuestionDataContainer"
            />

            <!-- Text Only -->
            <PageContentTextOnly
                v-if="questionType === FeedbackSessionQuestionsTypeEnum.TEXT_ONLY"
                :page-container="specificQuestionDataContainer"
            />

            <!-- Multi Choice Grid -->
            <PageContentMultiChoiceGrid
                v-if="questionType === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID"
                :page-container="specificQuestionDataContainer"
            />
        </template>

        <CustomLoader v-else />
    </div>
</template>

<script>
import store from "@/store";
import { diagLog } from "@/core/helpers/GlobalHelper";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { SpecificQuestionDataContainer } from "@/store/models/business/dashboard/level4/SpecificQuestionDataContainer";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import DedicatedQuestionFilter from "@/buying-teams/pages/business/question/components/DedicatedQuestionFilter";
import PageContentScoreImportance from "@/buying-teams/pages/business/question/components/PageContentScoreImportance";
import PageContentMultipleChoice from "@/buying-teams/pages/business/question/components/PageContentMultipleChoice";
import PageContentTextOnly from "@/buying-teams/pages/business/question/components/PageContentTextOnly";
import PageContentMultiChoiceGrid from "@/buying-teams/pages/business/question/components/PageContentMultiChoiceGrid";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";
import {
    SpecificCustomQuestionMultipleChoice
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionMultipleChoice";
import { SpecificCustomQuestionTextOnly } from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionTextOnly";
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";
import {
    SpecificCustomQuestionMultiChoiceGrid
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionMultiChoiceGrid";

export default {
    name: "DedicatedQuestion",
    components: {
        PageContentMultiChoiceGrid,
        PageContentTextOnly,
        PageContentMultipleChoice,
        CustomLoader,
        DedicatedQuestionFilter,
        PageContentScoreImportance,
    },
    data() {
        return {
            loading: true,
            specificQuestionDataContainer: null,
            questionId: null,
            feedbackSessions: [],
            isCustom: null,
            questionType: '',
            FeedbackSessionQuestionsTypeEnum
        };
    },
    computed: {
        businessData() {
            return store.getters.business;
        },
        questionFiltersState() {
            return store.getters.getBusinessFiltersState.singleQuestion
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.businessData.name,
            logo: this.businessData.icon_path
        });
    },
    beforeMount() {
        this.questionId = this.$route.params.id || null;
        this.isCustom = this.$route.query.isCustom || null;
        if (!this.questionId) {
            redirectToRouteWithName("business-dashboard-countries");
        }
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.SINGLE_QUESTION);
        this.loading = false;
        await this.fetchQuestionPageData(this.questionFiltersState);
    },
    methods: {
        async fetchQuestionPageData(filter = null) {
            try {
                this.specificQuestionDataContainer = null;
                let res = await store.dispatch("fetchSpecificQuestionData", {
                    filter,
                    questionId: this.questionId,
                    isCustom: !!this.isCustom
                });

                if (res.surveys && res.surveys.length) {
                    this.feedbackSessions = res.surveys;
                }

                if (this.isCustom) {
                    this.questionType = res.type;
                    switch (this.questionType) {
                        case FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE:
                            this.specificQuestionDataContainer = new SpecificQuestionDataContainer(res, true);
                            break;
                        case FeedbackSessionQuestionsTypeEnum.TEXT_ONLY:
                            this.specificQuestionDataContainer = new SpecificCustomQuestionTextOnly(res);
                            break;
                        case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE:
                            this.specificQuestionDataContainer = new SpecificCustomQuestionMultipleChoice(res);
                            break;
                        case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID:
                            this.specificQuestionDataContainer = new SpecificCustomQuestionMultiChoiceGrid(res);
                            break;
                    }
                } else {
                    this.questionType = FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE;
                    this.specificQuestionDataContainer = new SpecificQuestionDataContainer(res);
                }

                diagLog("PAGE DATA ", this.specificQuestionDataContainer);
            } catch (e) {
                console.error(e);
            }
        },
        handleBackPrevPage() {
            const backUrl = this.$router.options.history.state.back || '/business/dashboard/overview';
            this.$router.push(backUrl);
        },
    }
};
</script>

<style lang="scss">
.dedicated-question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .specific-question-filter {
        &.disabled-filter {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 4;
                background: #F6F6F6;
                opacity: 0.5;
                border-radius: 17px;
            }
        }
    }

    .question-page-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .section-card {
        background: #FFFFFF;
        box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 17px;

        .section-card-header {
            height: 62px;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .section-card-title {
            display: flex;
            align-items: center;
            gap: 8px;

            h2 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
            }

            span {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
            }
        }

        .section-card-actions {
            display: flex;
            align-items: center;
            gap: 22px;

            > a {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #435BF4;
                opacity: 0.87;

                &:hover {
                    opacity: 1;
                }
            }

            &__sort {
                width: 28px;
                height: 28px;
                background: transparent;
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__stats {
        display: flex;
        width: 100%;
        gap: 12px;

        .question-score {
            width: 338px;
        }

        .question-chart {
            overflow: hidden;
            padding: 10px;
            width: calc(100% - 350px);
        }
    }

    &__list {
        width: 100%;
        display: flex;
        gap: 12px;

        .list-item {
            width: 100%;
        }
    }

    &__user-score {
        width: 100%;
    }

    @media (max-width: 768px) {
        &__stats {
            flex-direction: column;

            .question-score, .question-chart {
                width: 100%;
            }
        }
        &__list {
            flex-direction: column;
        }
    }
}
</style>
