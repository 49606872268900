<template>
    <div class="dedicated-question-info">
        <div class="dedicated-question-info__cq-header" v-if="isCustomQuestion">
            <div class="question-title">
                <div class="custom-question-label">
                    <inline-svg src="/media/buying/icons/info-icon-gray.svg" width="15px" height="15px" />
                    {{ $t('Custom Question') }}
                </div>
                <h1>{{ questionData.productArea }} | {{ $t(questionData.type) }} </h1>
            </div>
            <div class="question-id">
                <span>{{ $t("Question ID") }}</span>
                <strong>{{ questionData.question_id || questionData.id }}</strong>
            </div>
        </div>
        <div class="dedicated-question-info__title" v-else>
            {{ questionData.productArea }} | {{ questionData.phase }}
        </div>
        <div class="dedicated-question-info__data" v-if="!isCustomQuestion">
            <div class="question-info">
                {{ $t('Question') }} {{ questionData.question_id }}
            </div>
            <div class="question-comment">
                <img src="/media/buying/icons/comment-blue.svg" width="14" alt="">
                <span>{{ questionData.commentsCount }}</span>
            </div>
        </div>
        <div class="dedicated-question-info__text">
            {{ questionData.question }}
        </div>
        <div class="dedicated-question-info__footer" v-if="!isCustomQuestion">
            <div class="question-tags">
                <div class="question-tags__label">
                    {{ $t("Tags") }}
                </div>
                <div
                    v-if="questionData.tags && questionData.tags.length"
                    class="question-tags__content">
                    <div
                        class="question-tags-item"
                        v-for="(tag, index) in questionData.tags"
                        :key="index"
                    >{{ tag }}</div>
                </div>
            </div>
            <div class="question-id">
                <span>{{ $t("ID") }}</span>
                <strong>{{ questionData.question_id }}</strong>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DedicatedQuestionInfo",
    props: {
        questionData: Object,
        isCustomQuestion: {
            type: Boolean,
            default: false,
        }
    }
};
</script>

<style scoped lang="scss">
.dedicated-question-info {
    width: 100%;
    padding: 29px 22px 18px 25px;
    background: #FFFFFF;
    box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
    border-radius: 17px;

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #434343;
        padding-bottom: 27px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    }

    &__cq-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 25px;
    }

    &__data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        margin-bottom: 8px;

        .question-info {
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            color: #757575;
            opacity: 0.5;
        }

        .question-comment {
            background: #F8F8F8;
            border-radius: 6px;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 15px 22px;

            span {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
            }
        }
    }

    &__text {
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 0.1px;
        color: #2A2A2A;
        padding-bottom: 14px;
    }

    &__footer {
        padding-top: 17px;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        .question-tags {
            display: flex;
            align-items: center;

            &__label {
                font-weight: 400;
                font-size: 14px;
                line-height: 13px;
                color: #757575;
                opacity: 0.5;
                margin-right: 14px;
            }

            &__content {
                display: flex;
                align-items: center;
                gap: 4px;

                .question-tags-item {
                    background: #F8F8F8;
                    border-radius: 5px;
                    padding: 8px 10px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                }
            }
        }
    }

    .question-id {
        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 158.02%;
            color: #969696;
            margin-right: 4px;
        }

        strong {
            font-weight: 600;
            font-size: 12px;
            line-height: 158.02%;
            color: #0A0A0A;
        }
    }

    .question-title {
        display: flex;
        align-items: center;
        gap: 20px;
        .custom-question-label {
            margin-left: 0;
        }
        h1 {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #434343;
            margin-bottom: 0;
        }
    }

    @media (max-width: 768px) {
        &__text {
            font-size: 18px;
        }
        &__footer {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .question-tags {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                &__content {
                    flex-wrap: wrap;
                }
            }
        }
        &__cq-header {
            flex-direction: column;
            position: relative;
            .question-title {
                width: 100%;
                justify-content: space-between;
            }
            .question-id {
                position: absolute;
                bottom: -10px;
                background: #fff;
                padding-left: 10px;
                right: 0;
            }
        }
    }

    @media (max-width: 550px) {
        &__cq-header {
            .question-title {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}
</style>
