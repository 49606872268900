import { SpecificPageDataContainerBase } from "@/store/models/shared/SpecificPageDataContainerBase";
import { QuestionModel } from "@/store/models/bank/CompareProductAreaModel";
import CircleChartModel from "@/store/models/CircleChartModel";

export class SpecificQuestionDataContainer extends SpecificPageDataContainerBase {
    isCustom: boolean = false;
    question: any;
    scoreSectionData: any;

    constructor(obj: any, isCustom: boolean = false) {
        super(obj);

        this.isCustom = isCustom;
        this.question = new QuestionModel(isCustom ? {
            ...obj,
            ...obj.stats
        } : obj.stats);

        this.scoreSectionData = {
            chartData: new CircleChartModel(obj.stats ? obj.stats : {}),
            respondents: obj.stats ? (obj.stats.respondents || 0) : 0,
            scores: obj.stats ? (obj.stats.scores || 0) : 0
        };
    }
}
