<template>
    <div class="question-all-comments">
        <div class="question-all-comments__loading" v-if="dataContainer.commentsSectionData.isLoading">
            <CustomLoader height="400px" />
        </div>
        <div class="question-all-comments__header">
            <span class="count">
              {{ $t("All Users & Comments") }}
              <span v-if="commentsSectionData.pagination">{{ commentsSectionData.pagination.totalItems }}</span>
            </span>

            <DashboardSortSelect
                v-model="customersSorting"
                :disabled="dataContainer.commentsSectionData.isLoading || !commentsSectionData.comments.length"
                @onSortingChange="triggerSortingChange"
            />
        </div>
        <div v-if="commentsSectionData.comments.length" class="question-all-comments__body">
            <div
                v-for="(comment, index) in commentsSectionData.comments"
                :key="index"
            >
                <DedicatedQuestionCommentItem
                    :commentData="comment"
                />
            </div>
        </div>
        <div v-else>
            <div class="empty-list-state py-10 px-15">
                <div class="empty-list-state__title mb-3">
                    {{ $t("No Comments") }}
                </div>
                <img class="mt-5" height="98" src="/media/buying/icons/no-message.svg" alt="">

                <div class="empty-list-state__subtitle mb-5">
                    {{ $t("No comments was made on this question") }}
                </div>
                <p>
                    {{ $t("You will see the customers who made comments in the list on your left. You can select and see their comments once made.")
                    }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardSortSelect from "@/buying-teams/shared-components/inputs/DashboardSortSelect";
import DedicatedQuestionCommentItem from "@/buying-teams/pages/business/question/components/DedicatedQuestionCommentItem";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
    name: "DedicatedQuestionAllComments",
    props: {
        dataContainer: Object,
        commentsSectionData: Object
    },
    emits: ["onSortingChange"],
    components: {
        CustomLoader,
        DedicatedQuestionCommentItem,
        DashboardSortSelect
    },
    data() {
        return {
            customersSorting: "highest_score"
        };
    },
    methods: {
        triggerSortingChange(sorting) {
            this.$emit("onSortingChange", sorting);
        }
    }
};
</script>

<style lang="scss">
.question-all-comments {
    overflow: hidden;
    height: 100%;
    position: relative;

    &__loading {
        position: absolute;
        z-index: 4;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #ffffffc2;
        backdrop-filter: blur(8px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__header {
        padding: 26px 23px 13px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .avatars-group {
            .avatar {
                width: 33px;
                height: 33px;
                margin-left: -12px;
                border: 2px solid #FFFFFF;
            }
        }

        .count {
            margin-left: 12px;
            font-weight: 700;
            font-size: 18px;
            color: #434343;
            padding-right: 2px;

            span {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px 11px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                margin-left: 8px;
            }
        }

        @media (max-width: 991px) {
            padding: 10px;
        }
    }

    &__body {
        padding-bottom: 2px;
        max-height: 860px;
        overflow-y: auto;
        padding-left: 29px;
    }
}

@media (max-width: 1260px) {
    .question-all-comments {
        &__header {
            padding-left: 23px;

            .count {
                margin-left: 0;
            }
        }
    }
}

@media (max-width: 500px) {
    .question-all-comments {
        &__header {
            margin-top: 10px;
        }

        &__body {
            .comment-item-content {
                &--header {
                    flex-direction: column;
                    align-items: flex-start;

                    .name {
                        margin-bottom: 10px;
                    }

                    .info {
                        flex-direction: column;
                        width: 100%;

                        .info-item {
                            border-right: none;
                            padding-left: 0;
                            padding-bottom: 5px;
                            padding-top: 5px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.0509803922);

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }

                &--body {
                    margin-left: -71px;
                }

                &--footer {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-left: -71px;

                    .question-info-item {
                        border-bottom: none;
                        border-left: 4px solid;

                        &.question-info-label--importance {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}
</style>
