<template>
    <div class="comment-item">
        <div>
            <el-avatar :src="commentData.userData.icon_path" class="avatar" />
        </div>
        <div class="comment-item-content">
            <div class="comment-item-content--header">
                <span class="name"> <strong>{{ commentData.userData.full_name }}</strong> </span>
                <div class="info">
                    <div class="info-item">
                        <span class="info-item-text">{{ commentData.userData.job_title }}</span>
                    </div>
                    <div v-if="commentData.comment" class="info-item">
                        <span class="info-item-text">{{ moment(commentData.comment.created_at).format("DD.MM.YYYY") }}</span>
                    </div>
                </div>
                <div v-if="commentData.comment && commentData.comment.feedback_session_id" class="session-info">
                    {{ $t('Session ID') }}: {{ commentData.comment.feedback_session_id }}
                </div>
            </div>
            <div class="comment-item-content--body">
                <img v-if="!commentData.comment" height="12" width="12" class="me-2" src="/media/buying/icons/no-session-found.svg" alt="">
                {{ commentData.comment ? commentData.comment.comment : $t('No Comment') }}
            </div>
            <div class="comment-item-content--footer">
                <div class="comment-item-content--footer--left">
                    <div v-for="item in scoreData" :class="`${item.statusClass} ${getLabelClass(item)} question-info-item`">

                        <div class="question-info-item--content">
                            <div class="question-info-label">
                                <span :class="`${getLabelClass(item)}`">{{ item.name }}</span>
                            </div>
                            <div class="question-info-item--header">
                                <span class="title">{{ item.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="commentData.comment" class="score-comment-made">
                    <span>{{ $t("Score and Comment Made on") }}</span>
                    <div class="score-comment-made__label">
                        <img :src="commentData.commenter.icon_path" alt="">
                        <strong>{{ commentData.commenter.name }}</strong>
                    </div>
                    <div class="score-comment-made__label">
                        <strong>{{ commentData.comment.country }}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getGapStatusClass, getStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import moment from "moment";

export default {
    name: "DedicatedQuestionCommentItem",
    props: {
        commentData: Object
    },
    data() {
        return {
            moment,
            numberDecimalsFormat
        };
    },
    methods: {
        getLabelClass(data) {
            switch (data.name) {
                case "Score":
                    return "question-info-label--score";
                case "Importance":
                    return "question-info-label--importance";
                default:
                    return "question-info-label--gap";
            }
        }
    },
    computed: {
        scoreData() {
            return [
                { name: "Score", value: this.numberDecimalsFormat(this.commentData.stats.score), statusClass: getStatusClass(this.commentData.stats.score) },
                { name: "Importance", value: this.numberDecimalsFormat(this.commentData.stats.importance), statusClass: "" },
                { name: "GAP", value: this.numberDecimalsFormat(this.commentData.stats.gap), statusClass: getGapStatusClass(this.commentData.stats.gap) }
            ];
        }
    }
};
</script>

<style scoped lang="scss">
.comment-item {
    display: flex;
    padding-top: 26px;

    .avatar {
        width: 51px;
        height: 51px;
        margin-right: 20px;
    }

    &-content {
        width: calc(100% - 71px);
        border-bottom: 1px solid #F2F2F2;

        &--header {
            display: flex;
            align-items: center;

            .name {
                font-weight: 400;
                font-size: 16px;
                color: #000000;
                margin-right: 4px;
            }

            .info {
                display: flex;

                &-item {
                    border-right: 1px solid #0000000D;
                    padding: 0 10px;

                    &:last-child {
                        border-right: none;
                    }

                    &-text {
                        font-weight: 400;
                        font-size: 12px;
                        color: #969696;
                    }

                    &-icon {
                        margin-right: 10px;
                    }
                }
            }

            .session-info {
                margin-left: auto;
                margin-right: 28px;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 158.02%;
                color: #2B2B2B;
            }
        }

        &--body {
            font-weight: 400;
            font-size: 16px;
            color: #757575;
            margin: 11px 0 20px;
            max-width: 90%;
        }

        &--footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: -2px;

            &--left {
                display: flex;
                align-items: flex-start;
            }

            .question-info-item {
                display: flex;
                align-items: center;
                padding: 0 20px 15px;
                border-bottom: 4px solid;

                &.status-danger {
                    border-color: #E22D21;

                    .question-info-label--gap:after {
                        background: #ffb2ad;
                    }

                    .question-info-label--score:after {
                        background: #E22D21;
                    }
                }

                &.status-warning {
                    border-color: #FBDC6C;

                    .question-info-label--score:after {
                        background: #FAD03B;
                    }
                }

                &.status-success {
                    border-color: #2BC490;

                    .question-info-label--gap:after {
                        background: #8fedcb;
                    }

                    .question-info-label--score:after {
                        background: #2BC490;
                    }
                }

                &.question-info-label--importance {
                    border-bottom: none;
                }

                &--header {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 700;
                        font-size: 16px;
                        color: #000000;
                        opacity: 0.87;
                        margin-right: 4px;
                    }

                    .green-type {
                        font-size: 10px;

                        span {
                            color: #1B9F72;
                        }
                    }

                    .red-type {
                        span {
                            color: #E22D21FF;
                        }
                    }
                }

                &--content {
                    display: flex;

                    .question-info-label {
                        margin-right: 4px;
                        display: flex;
                        align-items: center;

                        h4 {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 13px;
                            color: #000000B2;
                        }

                        span {
                            font-weight: 400;
                            font-size: 12.905px;
                            line-height: 16px;
                            text-align: center;
                            color: #0000007F;
                            padding-left: 16px;
                            position: relative;

                            &:after {
                                content: "";
                                position: absolute;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                            }
                        }

                        &--score:after {
                            background: #435BF4;
                        }

                        &--importance:after {
                            background: #CED4F8;
                        }

                        &--gap:after {
                            background: #2BC490;
                        }
                    }
                }
            }

            .score-comment-made {
                display: flex;
                gap: 8px;
                align-items: center;
                margin-right: 22px;

                span {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #969696;
                }

                &__label {
                    background: #ECECEC;
                    border-radius: 4px;
                    padding: 4px 8px;
                    font-weight: 700;
                    font-size: 11px;
                    line-height: 13px;
                    color: #000000;

                    img {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin-right: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }

            .status-deleted {
                font-weight: 400;
                font-size: 12px;
                color: #E22D21;
                background: rgba(226, 45, 33, 0.05);
                border-radius: 5px;
                padding: 7px;
                margin-left: auto;
            }

            @media(max-width: 1300px) {
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 15px;
                .score-comment-made {
                    margin-left: auto;
                }
            }

            @media(max-width: 1575px) {
                .status-deleted {
                    margin-left: unset;
                }
            }
        }
    }
}
</style>
